import model from './model';
import { ProjectHeaderWidgetComponentIds as ComponentIds } from './config/constants';
import { ProjectHeaderWidgetProps as Props } from './config/types';
import { getRichTextFormat, getDetailSubtitle } from '../../utils';

export default model.createController((controllerParams) => {
  const { $w, $widget } = controllerParams;
  let components: ReturnType<typeof getComponents>;

  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    title: $w(ComponentIds.Title),
    subtitle: $w(ComponentIds.SubTitle),
    description: $w(ComponentIds.Description),
    image: $w(ComponentIds.HeroImage),
  });

  const initByProps = (props: Props) => {
    const { data } = props;
    const { title, subtitle, description, heroImage } = data;
    components.title.text = title;
    components.subtitle.richText = subtitle ? getDetailSubtitle(subtitle) : '';
    components.description.richText = description
      ? getRichTextFormat(description)
      : '';
    if (heroImage?.imageInfo?.url) {
      components.image.src = `https://static.wixstatic.com/media/${heroImage.imageInfo.url}`;
      // temporary hard code base url
    }
  };

  $widget.onPropsChanged((_, nextProps) => {
    initByProps(nextProps);
  });

  return {
    pageReady: async () => {
      components = getComponents();
      initByProps($widget.props);
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});
