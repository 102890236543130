import model from './model';
import { CategoryWidgetComponentIds as ComponentIds } from './config/constants';
import { CategoryWidgetProps as Props } from './config/types';
import { Project } from '@wix/ambassador-portfolio-projects-v1-project/build/es/types';
import { commonImagesToWixCode } from '../../converters/mediaConverter';
import { getProjectUrl, getRichTextFormat } from '../../utils';
import { Item } from '@wix/ambassador-portfolio-project-items-v1-project-item/types';

export default model.createController((controllerParams) => {
  const { $w, $widget, flowAPI } = controllerParams;
  let components: ReturnType<typeof getComponents>;
  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    title: $w(ComponentIds.Title),
    description: $w(ComponentIds.Description),
    gallery: $w(ComponentIds.Gallery),
  });

  const getWixCodePGItems = (projects: Project[], categorySlug) => {
    const projectCoverImages = projects.map((project: Project) => {
      const item: Item = {
        link: {
          url: getProjectUrl(flowAPI, categorySlug, project.slug),
        },
        image: {
          imageInfo: project.coverImage?.imageInfo,
        },
      };
      return item;
    });
    return commonImagesToWixCode(projectCoverImages);
  };

  const initByProps = (props: Props) => {
    const { data } = props;
    const { title, description } = data.category;
    if (data.category.id) {
      components.title.text = title;
      components.description.richText = description
        ? getRichTextFormat(description)
        : '';
      components.gallery.items = getWixCodePGItems(
        data.projects,
        data.category.slug,
      );
    }
  };

  $widget.onPropsChanged((_, nextProps) => initByProps(nextProps));

  return {
    pageReady: async () => {
      components = getComponents();
      initByProps($widget.props);
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});
