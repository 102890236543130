import model from './model';
import { ProjectWidgetComponentIds as ComponentIds } from './config/constants';
import { ProjectWidgetProps as Props } from './config/types';
import { ProjectItem } from '@wix/ambassador-portfolio-project-items-v1-project-item/build/es/types';
import { commonImagesToWixCode } from '../../converters/mediaConverter';

export default model.createController((controllerParams) => {
  const { $w, $widget } = controllerParams;
  let components: ReturnType<typeof getComponents>;
  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    projectHeaderWidget: $w(ComponentIds.Header),
    gallery: $w(ComponentIds.Gallery),
    navigationWidget: $w(ComponentIds.NavigationWidget),
  });

  const getWixCodePGItems = (projectItems: ProjectItem[]) => {
    const projectCoverImages = projectItems.map((projectItem: ProjectItem) => ({
      ...projectItem.item,
    }));
    return commonImagesToWixCode(projectCoverImages);
  };

  const initByProps = (props: Props) => {
    const { data } = props;
    if (data.project.id) {
      components.projectHeaderWidget.data = {
        title: data.project.title,
        subtitle: data.project?.details?.details,
        description: data.project.description,
        heroImage: data.project.coverImage,
      };
      components.gallery.items = getWixCodePGItems(data.items);
    }

    components.navigationWidget.data = {
      hideNext: false,
      hidePrev: false,
    };
  };

  $widget.onPropsChanged((_, nextProps) => initByProps(nextProps));

  return {
    pageReady: async () => {
      components = getComponents();
      initByProps($widget.props);
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});
